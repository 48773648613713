import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table, Radio } from "antd";
import EditableTableCell from "../utils/EditableTableCellv2";
import { getLinearCorrelationData } from "../../../utils/ExecutiveData/getLinearCorrelationData.js";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ScatterChart,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import add_item from "../../../assets/add_item.svg";
import removeSVG from "../../../assets/close.svg";

const defaultAwardStructure = [
  {
    key: 0,
    performanceLevel: "Below Lower Threshold",
    percentileTarget: 25,
    multiplier: 0,
  },
  {
    key: 1,
    performanceLevel: "Lower Threshold",
    percentileTarget: 25,
    multiplier: 0.75,
  },
  {
    key: 2,
    performanceLevel: "Target",
    percentileTarget: 55,
    multiplier: 1,
  },
  {
    key: 3,
    performanceLevel: "Upper Threshold",
    percentileTarget: 75,
    multiplier: 2,
  },
  {
    key: 4,
    performanceLevel: "Above Upper Threshold",
    percentileTarget: 75,
    multiplier: 2,
  },
];

let awardTableColumnParams = [
  {
    title: `Remove?`,
    dataIndex: "remove",
    dataKey: "remove",
    align: "left",
    width: 20,
  },
  {
    title: "Threshold Level Breakpoint",
    dataKey: "performanceLevel",
    dataType: "string",
  },
  {
    title: "Percentile Breakpoint",
    dataKey: "percentileTarget",
    dataType: "fixed",
    minValue: 0,
    maxValue: 100,
  },
  {
    title: "Multiplier",
    dataKey: "multiplier",
    dataType: "fixed",
    minValue: 0,
    maxValue: 10,
  },
];

export default function AwardsTable({
  ticker,
  awardParams,
  results,
  negativePayoutStructure,
  setAllAwardsValues,
  setTotalAwardValue,
}) {
  const [awardStructure, setAwardStructure] = useState([]);

  const removeElement = (index) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: 5,
      }}
      onClick={() =>
        setAwardStructure((prev) => {
          const newStructure = prev.filter((item) => item.key !== index);
          return prev.length <= 3 ? prev : newStructure;
        })
      }
    >
      <img src={removeSVG} alt="remove" width={11} />
    </div>
  );

  useEffect(() => {
    setAwardStructure(
      JSON.parse(JSON.stringify(defaultAwardStructure)).map((item, index) => ({
        ...item,
        remove: removeElement(index),
      }))
    );
  }, []);

  const addAwardColumn = useCallback(() => {
    setAwardStructure([
      ...awardStructure,
      {
        key: awardStructure[awardStructure.length - 1].key + 1,
        remove: removeElement(
          awardStructure[awardStructure.length - 1].key + 1
        ),
        performanceLevel: "New Breakpoint",
        percentileTarget:
          awardStructure[awardStructure.length - 1].percentileTarget,
        multiplier: awardStructure[awardStructure.length - 1].multiplier,
      },
    ]);
  }, [awardStructure]);

  const [interpolationMethodSelected, setInterpolationMethodSelected] =
    useState("Linear");

  // Reset to default award structure on load
  // Can use this to Set to user's prefrences/ prev values in the future.
  // useEffect(() => {
  //   const newDefaultAwardStructure = JSON.parse(
  //     JSON.stringify(defaultAwardStructure)
  //   );
  //   setAwardStructure(newDefaultAwardStructure);
  //   setUpdateCount((prev) => prev + 1);
  // }, []);

  const [updateCount, setUpdateCount] = useState(0);

  const sortedAwardPercentiles = useMemo(() => {
    if (!awardStructure || !awardStructure.length) return [];
    // if (updateCount > -1)
    return awardStructure.sort((a, b) => a.key - b.key);
  }, [awardStructure]);

  const minPercentile = useMemo(() => {
    const minPercentile = sortedAwardPercentiles[0];
    return minPercentile;
  }, [sortedAwardPercentiles]);

  const maxPercentile = useMemo(() => {
    const maxPercentile =
      sortedAwardPercentiles[sortedAwardPercentiles.length - 1];
    return maxPercentile;
  }, [sortedAwardPercentiles]);

  const awardStructureScatterData = useMemo(() => {
    //TODO: Set to true if below target award is not a part of strucutre
    const useZeroAwardStart = false;

    if (updateCount < 0 || !sortedAwardPercentiles.length) return;
    const initialValue = {
      percentile: 0,
      multiplier: useZeroAwardStart ? 0 : minPercentile.multiplier,
    };

    const data = [initialValue];

    if (initialValue.multiplier === 0) {
      data.push({
        percentile: minPercentile.percentileTarget,
        multiplier: 0,
      });
    }
    sortedAwardPercentiles.forEach((item, index) => {
      const currentValue = {
        percentile: item.percentileTarget,
        multiplier: item.multiplier,
      };
      data.push(currentValue);

      if (
        interpolationMethodSelected === "Step-Wise" &&
        index !== sortedAwardPercentiles.length - 1
      ) {
        data.push({
          percentile: sortedAwardPercentiles[index + 1].percentileTarget,
          multiplier: item.multiplier,
        });
      }

      if (index === sortedAwardPercentiles.length - 1) {
        data.push({ percentile: 100, multiplier: maxPercentile.multiplier });
      }
    });

    return data;
  }, [
    sortedAwardPercentiles,
    minPercentile,
    maxPercentile,
    interpolationMethodSelected,
    updateCount,
  ]);
  useEffect(() => {
    console.log(
      awardStructure,
      !awardTableColumnParams.some((item) => item.dataKey === "remove")
    );
    if (
      awardStructure &&
      awardStructure.length > 3 &&
      !awardTableColumnParams.some((item) => item.dataKey === "remove")
    ) {
      awardTableColumnParams.unshift({
        title: `Remove?`,
        dataIndex: "remove",
        dataKey: "remove",
        align: "left",
        width: 20,
      });
    }

    if (
      awardStructure &&
      awardStructure.length <= 3 &&
      awardTableColumnParams.some((item) => item.dataKey === "remove")
    ) {
      awardTableColumnParams = awardTableColumnParams.filter(
        (item) => item.dataKey !== "remove"
      );
    }
    setUpdateCount((prev) => prev + 1);
  }, [awardStructure]);

  const awardsTableColumns = useMemo(() => {
    const newAwardsTableColumns = awardTableColumnParams.map((column) => ({
      title: column.title,
      dataIndex: column.dataKey,
      key: column.dataKey,
      updateKey: updateCount,
      render: (cellData, rowData) => {
        return typeof cellData === "object" ? (
          cellData
        ) : (
          <EditableTableCell
            cellData={cellData}
            dataType={column.dataType}
            updateItem={sortedAwardPercentiles}
            updateIndex={sortedAwardPercentiles.indexOf(rowData)}
            updateKey={column.dataKey}
            setUpdateItem={setAwardStructure}
            originalValue={sortedAwardPercentiles[column.dataKey]} //This may need to be defaultAwardStructure[column.dataKey]
            currentValue={cellData}
            setUpdateCount={setUpdateCount}
            displayZero={true}
            key={column.dataKey}
            minValue={column?.minValue}
            maxValue={column?.maxValue}
          />
        );
      },
    }));
    return newAwardsTableColumns;
  }, [sortedAwardPercentiles, updateCount]);

  useEffect(() => {
    if (!results) return;

    const calculateAwardMultiplier = (
      percentile,
      minAward,
      maxAward,
      sortedAwardPercentiles,
      interpolationMethodSelected
    ) => {
      if (percentile <= minAward.percentileTarget) {
        return minAward.multiplier;
      }
      if (percentile >= maxAward.percentileTarget) {
        return maxAward.multiplier;
      }
      // Otherwise - get interpolated/closest value
      let closestPercentileAward = minAward;
      let closestPercentileAwardIndex = 0;
      for (const award of sortedAwardPercentiles) {
        if (percentile >= award.percentileTarget) {
          closestPercentileAward = award;
          closestPercentileAwardIndex = sortedAwardPercentiles.indexOf(award);
        }
      }

      if (interpolationMethodSelected === "Step-Wise") {
        return closestPercentileAward.multiplier;
      }

      const correlationData = getLinearCorrelationData(
        sortedAwardPercentiles.slice(
          closestPercentileAwardIndex,
          closestPercentileAwardIndex + 2
        ),
        "percentileTarget",
        "multiplier"
      );

      // TODO: Check this once add/removeing breakpoints allowed
      // console.log(correlationData);

      const yIntercept = correlationData.yIntercept;
      const slope = correlationData.slope;
      const calculateInperpolatedAward = (percentile) =>
        slope * percentile + yIntercept;
      return calculateInperpolatedAward(percentile);
    };
    const maximumNumberOfAwards = negativePayoutStructure
      ? parseInt(
          awardParams.negitivePayoutMaximum.value.toString().replaceAll(",", "")
        )
      : null;

    const allAwards = [];
    for (const rankingResult of results) {
      const multiplier = calculateAwardMultiplier(
        parseFloat(rankingResult.percentile),
        minPercentile,
        maxPercentile,
        sortedAwardPercentiles,
        interpolationMethodSelected
      );
      const numberOfShares = parseInt(
        awardParams.numberOfShares.value.toString().replaceAll(",", "")
      );

      //Account for negative award structure
      const postiveReturnChance = rankingResult?.["positiveReturn"];
      const adjustedNumberOfShares = !negativePayoutStructure
        ? numberOfShares * multiplier
        : numberOfShares * multiplier * postiveReturnChance +
          (numberOfShares * multiplier < maximumNumberOfAwards
            ? numberOfShares * multiplier * (1 - postiveReturnChance)
            : maximumNumberOfAwards * (1 - postiveReturnChance));

      const valueOfAwards =
        adjustedNumberOfShares *
        parseFloat(awardParams.stockPrice.value.toString().replaceAll(",", ""));

      const newAward = {
        rank: parseFloat(rankingResult.rank),
        percentile: parseFloat(rankingResult.percentile),
        chanceOfPercentile: rankingResult?.[ticker] * 100,
        positiveReturn: postiveReturnChance ? 1 - postiveReturnChance : null,
        multiplier: multiplier,
        numberOfAwards: adjustedNumberOfShares,
        valueOfAwards: valueOfAwards,
        valueAsPartOfTotal: valueOfAwards * rankingResult?.[ticker],
      };
      allAwards.push(newAward);
    }

    setAllAwardsValues(allAwards);

    let currentTotalAwardValue = 0;
    for (const award of allAwards) {
      currentTotalAwardValue += award.valueAsPartOfTotal;
    }
    setTotalAwardValue(currentTotalAwardValue);
  }, [
    ticker,
    awardParams,
    results,
    sortedAwardPercentiles,
    minPercentile,
    maxPercentile,
    negativePayoutStructure,
    setAllAwardsValues,
    setTotalAwardValue,
    interpolationMethodSelected,
    updateCount,
  ]);

  return (
    <div>
      <h3 style={{ fontSize: 25 }}>Edit Award Structure:</h3>

      <div style={{ border: "1px solid lightgrey", padding: 10 }}>
        <Table
          dataSource={sortedAwardPercentiles}
          columns={awardsTableColumns}
          pagination={{ hideOnSinglePage: true }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            // textAlign: "center",
            alignItems: "center",
            margin: "10px 0 0 0",
            flexWrap: "wrap",
            rowGap: "5px",
          }}
        >
          {awardStructure.length < 10 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => addAwardColumn()}
            >
              <img
                src={add_item}
                alt="add_item"
                width={15}
                style={{
                  margin: "0 5px 2px 0.25px",
                  filter: "invert(0.5) sepia(1) saturate(5) hue-rotate(185deg)",
                }}
                fill={"var(--link-blue"}
              />
              <div style={{ fontSize: 15, color: "var(--link-blue)" }}>
                Add a Breakpoint
              </div>
            </div>
          )}

          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {"Interpolation Method: ".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          <Radio.Group
            defaultValue="Linear"
            value={interpolationMethodSelected}
            onChange={(e) => {
              setInterpolationMethodSelected(e.target.value);
            }}
            buttonStyle="solid"
          >
            <Radio.Button value="Linear">Linear</Radio.Button>
            <Radio.Button value="Step-Wise">Step-Wise</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div style={{ width: "", marginTop: 20 }}>
        <h3 style={{ fontSize: 25 }}>Award Structure Visualization</h3>
        <ResponsiveContainer width="100%" height={225}>
          <ScatterChart
            key={updateCount}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              // type="percentile"
              dataKey="percentile"
              type="number"
              name="Percentile"
              domain={[0, 100]}
              tickCount={10}
            >
              <Label value="Percentile" offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              dataKey="multiplier"
              name="Multiplier"
              unit="x"
              domain={[0, "dataMax + 0.1"]}
              interval="equidistantPreserveStart"
            >
              <Label
                value="Multiplier"
                offset={10}
                angle={270}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />

            <Scatter
              name="Percentile Target"
              data={awardStructureScatterData}
              fill="var(--secondary)"
              strokeWidth={3}
              line
              // shape={null} //Comment out if datapoints need to be visualized
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
