import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import stateList from "../../assets/state.json";
import validCities from "../../assets/validCities.json";
import { Slider, Select, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTableFilter,
  setTableFilter,
} from "../../redux/actions/TableFilterAction";
import {
  setFirstFilter,
  setSecondFilter,
} from "../../redux/actions/ComparisonTableAction";
import Sector from "../../assets/NasdaqSectors.json";
import Industries from "../../assets/NasdaqIndustries.json";
import {
  customParseFloat,
  MIN_YEAR,
} from "../../utils/Constants/systemSetting";
import dataFilter from "../../utils/DataFilter/dataFilter";
import ExportHomepagePDFButton from "./ExportHomepagePDFButton.js";
import { DEFAULT_YEAR } from "../../utils/Constants/systemSetting";
import { maxExecutiveRanks } from "../../utils/Constants/executiveMaxRank.js";
import { sortClosestExecutive } from "../../utils/ExecutiveData/sortClosest";
import { measureOptions } from "../../utils/ExecutiveData/executiveMeasure";

// const validTickers = require("../../assets/validTickers.json");

const { Option } = Select;
const children = [];
for (let i = 0; i < Sector.length; i++) {
  children.push(<Option key={Sector[i]}>{Sector[i]}</Option>);
}

export default function TableFilter({
  result,
  csuite,
  allData,
  data,
  delay,
  update,
  setTriggerDelay,
  setTriggerUpdate,
  progressPercent,
  filteredMarketCap,
  handleResetPagination,
  useCalculatedTotals,
  setUseCalculatedTotals,
  setPDFprocessing,
  setPDFprocessingForAllUsers,
  allowExecutiveTableExport = false,
  MAX_YEAR = DEFAULT_YEAR,
  CORRECTED_MIN_YEAR = MIN_YEAR,
}) {
  const [indexTickers, setIndexTickers] = useState({});
  const { filter: reduxFilter } = useSelector(
    (state) => state.TableFilterReducer
  );
  const user = useSelector((state) => state.UserReducer);
  const [allowForDiffPercent, setAllowForDiffPercent] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const version = localStorage.getItem("version") || "1.0.0";
    async function fetchLocal(path) {
      const response = await fetch(path);
      const result = await response.json();
      return result;
    }
    async function getIndexes() {
      const DowData = await fetchLocal(`/data/DowTickers.json?ver=${version}`);
      const RussellData = await fetchLocal(
        `/data/Russell3000Tickers.json?ver=${version}`
      );
      const SP500Data = await fetchLocal(
        `/data/SP500Tickers.json?ver={version}`
      );
      setIndexTickers({ DowData, RussellData, SP500Data });
    }
    getIndexes();
  }, []);

  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: executives } = useSelector(
    (state) => state.ExecutivesReducer
  );
  const { allBoardPay } = useSelector((state) => state.CompanyReducer);

  const [cohortChildren, setCohortChildren] = useState([]);
  const [customCohortChildren, setCustomCohortChildren] = useState([]);
  const [companyChildren, setCompanyChildren] = useState([]);
  const [executiveChildren, setExecutiveChildren] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const initFilter = useSelector((state) => state.TableFilterReducer);

  const [newValidTickers, setNewValidTickers] = useState([]);
  const [newValidCohorts, setNewValidCohorts] = useState([]);
  const [newValidCities, setNewValidCities] = useState([]);
  const [newValidStates, setNewValidStates] = useState([]);
  const [filteredIndustries, setFilteredIndustries] = useState(Industries);
  const [newValidExecutives, setNewValidExecutives] = useState(executives);
  const [companyRankCompanies, setCompanyRankCompanies] = useState([]);

  const { currentTable } = useSelector((state) => state.ComparisonTableReducer);

  const [show, toggle] = useState(false);

  const [filter, setFilter] = useState({ ...reduxFilter });

  useEffect(() => {
    setFilter({ ...reduxFilter });
  }, [initFilter, reduxFilter]);

  // To only provide industrys available with current data: //
  // useEffect(() => {
  //   if (!delay || update) {
  //     let newFilteredIndustries = [];
  //     for (const executiveData of data) {
  //       if (
  //         executiveData?.industry &&
  //         executiveData?.industry !== "Other" &&
  //         !newFilteredIndustries.includes(executiveData?.industry)
  //       ) {
  //         newFilteredIndustries.push(executiveData?.industry);
  //       }
  //     }
  //     newFilteredIndustries.sort();
  //     newFilteredIndustries.push("Other");
  //     setFilteredIndustries(
  //       newFilteredIndustries
  //       // Industries.filter((industry) => ...)
  //     );
  //   }
  // }, [filter, data, delay, update]);

  const [stateChildren, setStateChildren] = useState();
  useEffect(() => {
    if (!delay || update) {
      let tempNewValidStates = newValidStates.sort();
      tempNewValidStates.push("Other");
      setStateChildren(
        stateList
          .filter((item) => tempNewValidStates.includes(item))
          .map((item, index) => {
            return (
              <Option value={item} key={item}>
                {item}
              </Option>
            );
          })
      );
    }
  }, [filter, data, delay, update, newValidStates]);

  const [validCitiesChildren, setValidCitiesChildren] = useState([]);
  useMemo(() => {
    if (!delay || update) {
      let tempNewValidCities = newValidCities.sort();
      tempNewValidCities.push("Other");
      setValidCitiesChildren(
        validCities
          .filter(
            (item) =>
              tempNewValidCities.includes(item.city.toLowerCase()) &&
              (filter.companyState.length === 0 ||
                filter.companyState.includes(item.state))
          )
          .map((res, index) => {
            return (
              <Option key={res.city + ", " + res.state}>
                {res.city}, {res.state}
              </Option>
            );
          })
      );
    }
  }, [filter, newValidCities, delay, update]);

  const updateLocationFilterOptions = (executiveData, locationType) => {
    const titleCase = (txt) =>
      txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();

    if (!delay || update) {
      locationType = filter.locationFilter + titleCase(locationType); // can be companyCity or executiveCity
      if (
        (companies[executiveData.Ticker]?.[
          locationType.replace("company", "")
        ] ||
          executiveData?.[locationType]) &&
        executiveData?.[locationType] !== "Other"
      ) {
        return (
          companies[executiveData.Ticker]?.[
            locationType.replace("company", "")
          ] || executiveData?.[locationType]
        );
      }
      return undefined;
    }
  };

  const updateFilterOptions = (filterName) => {
    // if (!delay || update) {
    const newData =
      filterName === "companyRank"
        ? new dataFilter(allData, filter.currentYear, companies).filterIndexes(
            filter.index
          )
        : new dataFilter(allData, filter.currentYear, companies)
            .filterCSuite(csuite)
            .salaryRange(filter.salary.salaryMin, filter.salary.salaryMax)
            .executiveRankRange(
              filter.executiveRank.executiveRankMin,
              filter.executiveRank.executiveRankMax
            )
            .filterIndustry(filterName === "industry" ? [] : filter.industry)
            .filterSector(filter.sector)
            .filterCompany(filterName === "company" ? [] : filter.company)
            .filterCompanyLocation(
              filterName === "state" ? [] : filter.companyState
            )
            .filterExecutiveState(
              filterName === "state" ? [] : filter.executiveState
            )
            .filterCompanyCity(filterName === "city" ? [] : filter.companyCity)
            .filterExecutive(
              filterName === "executive" ? [] : filter.executives
            )
            .filterExecutiveCity(
              filterName === "city" ? [] : filter.executiveCity
            )
            .percentileCompensation(
              filter.percentile.percentileMin,
              filter.percentile.percentileMax
            )
            .marketCapRange(
              filter.marketCap.marketCapMin,
              filter.marketCap.marketCapMax
            )
            .employeeRange(
              filter.employees.employeesMin,
              filter.employees.employeesMax
            )
            .netIncomeRange(
              filter.netIncome.netIncomeMin,
              filter.netIncome.netIncomeMax
            )
            .revenueRange(filter.revenue.revenueMin, filter.revenue.revenueMax)
            .assetsRange(filter.assets.assetsMin, filter.assets.assetsMax)
            .companyRankRange(
              filter.companyRank.companyRankMin,
              filter.companyRank.companyRankMax,
              filteredMarketCap
            )
            .filterIndexes(filter.index)
            .filterFounders(filter.founders);
    const filterOptions = [];
    const filteredData = [...newData.data];
    for (const executiveData of filteredData) {
      switch (filterName) {
        case "company":
          if (
            executiveData?.Ticker &&
            !filterOptions.includes(executiveData.Ticker)
          ) {
            filterOptions.push(executiveData.Ticker);
          }
          break;
        case "cohort":
          if (
            executiveData?.Ticker &&
            !filterOptions.includes(executiveData.Ticker)
          ) {
            filterOptions.push(executiveData.Ticker);
          }
          break;
        case "proxyCohort":
          if (
            executiveData?.Ticker &&
            !filterOptions.includes(executiveData.Ticker)
          ) {
            filterOptions.push(executiveData.Ticker);
          }
          break;
        case "companyRank":
          if (
            executiveData?.Ticker &&
            !filterOptions.includes(executiveData.Ticker)
          ) {
            filterOptions.push(executiveData.Ticker);
          }
          break;
        case "state":
          const possibleNewState = updateLocationFilterOptions(
            executiveData,
            "state"
          );
          if (possibleNewState && !filterOptions.includes(possibleNewState)) {
            filterOptions.push(possibleNewState);
          }
          break;
        case "city":
          const possibleNewCity = updateLocationFilterOptions(
            executiveData,
            "city"
          );
          if (possibleNewCity && !filterOptions.includes(possibleNewCity)) {
            filterOptions.push(possibleNewCity.toLowerCase());
          }
          break;
        case "industry":
          if (
            executiveData?.industry &&
            !filterOptions.includes(executiveData.industry)
          ) {
            filterOptions.push(executiveData.industry);
          }
          break;
        case "executive":
          if (
            executiveData?.name &&
            !filterOptions.includes(executiveData.name)
          ) {
            filterOptions.push(executiveData);
          }
          break;
        default:
          console.log("Filter Error: No filter type provided.");
      }
    }
    return filterOptions;
    // }
  };

  useMemo(() => {
    if (!delay || update) {
      setNewValidTickers(updateFilterOptions("company"));
      setNewValidCohorts(updateFilterOptions("cohort"));
      setNewValidStates(updateFilterOptions("state"));
      setNewValidCities(updateFilterOptions("city"));
      setFilteredIndustries(updateFilterOptions("industry"));
      setCompanyRankCompanies(updateFilterOptions("companyRank"));
      setNewValidExecutives(updateFilterOptions("executive"));
    }
  }, [delay, update]);

  useEffect(() => {
    const sortClosestCompany = (a, b) => {
      if (!!searchVal) {
        if (
          a.Ticker.toLowerCase() === searchVal.toLowerCase() &&
          b.Ticker.toLowerCase() !== searchVal.toLowerCase()
        ) {
          return -1;
        } else if (
          b.Ticker.toLowerCase() === searchVal.toLowerCase() &&
          a.Ticker.toLowerCase() !== searchVal.toLowerCase()
        ) {
          return 1;
        } else if (
          a?.AlternateTickers?.some((ticker) =>
            ticker.toLowerCase().includes(searchVal.toLowerCase())
          ) &&
          !b?.AlternateTickers?.some((ticker) =>
            ticker.toLowerCase().includes(searchVal.toLowerCase())
          )
        ) {
          return -1;
        } else if (
          b?.AlternateTickers?.some((ticker) =>
            ticker.toLowerCase().includes(searchVal.toLowerCase())
          ) &&
          !a?.AlternateTickers?.some((ticker) =>
            ticker.toLowerCase().includes(searchVal.toLowerCase())
          )
        ) {
          return 1;
        } else if (a.Company && b.Company) {
          return (
            b.Company.toLowerCase().startsWith(searchVal.toLowerCase()) -
            a.Company.toLowerCase().startsWith(searchVal.toLowerCase())
          );
        }
      } else {
        if (a?.Ticker && b?.Ticker) {
          const nameA = a.Company.toLowerCase();
          const nameB = b.Company.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      }
    };

    setCohortChildren(
      Object.values(companies)
        .sort((a, b) => sortClosestCompany(a, b))
        .filter(
          (item) =>
            (newValidCohorts.includes(item?.Ticker) ||
              newValidTickers.includes(item?.Ticker)) &&
            (filter.cohortFilter === "analyst"
              ? item?.AnalystCohort?.length > 0
              : item?.ProxyCohort?.length > 0 ||
                item?.ProxyCohortCompensation?.length > 0)
        )
        .map((res, index) => {
          const tickers = res.AlternateTickers.length
            ? res.AlternateTickers.join()
            : res.Ticker;
          return (
            <Option key={res.Company + " [" + tickers + "]"}>
              {res.Company + " [" + tickers + "]"}
            </Option>
          );
        })
    );

    setCustomCohortChildren(
      !(user?.customPeerGroups && Object.keys(user?.customPeerGroups).length)
        ? []
        : Object.keys(user?.customPeerGroups).map((key) => {
            return <Option key={key}>{key}</Option>;
          })
    );

    setCompanyChildren(
      Object.values(companies)
        .sort((a, b) => sortClosestCompany(a, b))
        .filter((item) => {
          return newValidTickers.includes(item?.Ticker);
        })
        .map((res, index) => {
          const tickers = res.AlternateTickers.length
            ? res.AlternateTickers.join()
            : res.Ticker;
          return (
            <Option key={res.Company + " [" + tickers + "]"}>
              {res.Company + " [" + tickers + "]"}
            </Option>
          );
        })
    );

    const currentExecutives =
      csuite.includes("Board Pay") && allBoardPay
        ? Object.values(allBoardPay)
        : executives;

    setExecutiveChildren(
      currentExecutives
        .filter(
          (item) =>
            newValidExecutives.includes(item) &&
            item.compensation?.[filter.currentYear]?.total >= 0 &&
            (searchVal === "" ||
              searchVal
                .toLowerCase()
                .split(" ")
                .every(
                  (val) =>
                    val === "" ||
                    item.name.toLowerCase().includes(val) ||
                    item.Ticker.toLowerCase().includes(val) ||
                    item.company.toLowerCase().includes(val)
                ))
        )
        .sort((a, b) => {
          return sortClosestExecutive(a, b, searchVal);
        })
        .map((res) => (
          <Option key={res.name + " [" + res.Ticker + "]" + res.key}>
            {res.name + " [" + res.Ticker + "]"}
          </Option>
        ))
    );
  }, [
    companies,
    user?.customPeerGroups,
    searchVal,
    data,
    newValidTickers,
    newValidCohorts,
    executives,
    allBoardPay,
    newValidExecutives,
    filter, // "filter" may cause infinate loop, remove if it causes errors
    csuite,
  ]);

  const renderIndustryOptions = () => {
    const sortClosestIndustry = (a, b) => {
      // Sort most relevent results first
      if (!!searchVal) {
        if (a && b) {
          return (
            b.toLowerCase().startsWith(searchVal.toLowerCase()) -
            a.toLowerCase().startsWith(searchVal.toLowerCase())
          );
        } else {
          return 0;
        }
      } else {
        if (a && b) {
          const nameA = a.toLowerCase();
          const nameB = b.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      }
    };
    return filteredIndustries
      .sort((a, b) => sortClosestIndustry(a, b))
      .map((industry, index) => (
        <Option key={index} value={`${industry}`}>
          {industry}
        </Option>
      ));
  };

  const handleYearChange = (e) => {
    let values = { ...filter, currentYear: e.target.value };
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  };

  const handleFounderChange = (e) => {
    let values = { ...filter, founders: e.target.value };
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  };

  const handleIndexChange = (e) => {
    let values = { ...filter, indexSelected: e.target.value };
    let currentIndexTickers = [];
    switch (values.indexSelected) {
      case "Dow Jones":
        currentIndexTickers = indexTickers.DowData[filter.currentYear].Tickers;
        break;
      case "S&P 500":
        currentIndexTickers =
          indexTickers.SP500Data[filter.currentYear].Tickers;
        break;
      case "Russell 3000":
        currentIndexTickers =
          indexTickers.RussellData[filter.currentYear].Tickers;
        break;
      default:
        break;
    }
    values = { ...values, index: currentIndexTickers };
    //reset company rank too, max values will be different for each index:
    values = {
      ...values,
      companyRank: {
        companyRankMin: null,
        companyRankMax: null,
      },
    };
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  };

  const handleLocationChange = (e) => {
    let currentFilter = e.target.value;
    let values = {};
    if (currentFilter !== "company") {
      values = {
        ...filter,
        executiveState: [...filter.companyState],
        companyState: [],
      };
      values = {
        ...values,
        executiveCity: [...filter.companyCity],
        companyCity: [],
      };
    } else {
      values = {
        ...filter,
        companyState: [...filter.executiveState],
        executiveState: [],
      };
      values = {
        ...values,
        companyCity: [...filter.executiveCity],
        executiveCity: [],
      };
    }
    values = { ...values, locationFilter: e.target.value };
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  };

  const handleCohortChange = (e) => {
    let values = {
      ...filter,
      cohortFilter: e.target.value,
      company: [],
    };
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  };

  useEffect(() => {
    const companies = updateCohortCompanies(filter.cohort);
    let values = {
      ...filter,
      company: companies,
    };
    setFilter({ ...values });
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
  }, [filter.cohortFilter]);

  const handleChangeRange = (e) => {
    const { name } = e.target;
    const values = { ...filter };

    const value = e.target.value.replaceAll(",", "");
    const reg = /^[0-9,]+$/;
    const neg = /^0$|^-?[1-9]\d*(\.\d+)?$/;

    //netIncome can be negitive
    if (name.includes("netIncome")) {
      if (value.trim() === "") {
        values.netIncome[name] = "";
      } else if (value.trim() === "-") {
        values.netIncome[name] = "-";
      } else {
        if (neg.test(Number(value))) {
          values.netIncome[name] = parseInt(value).toLocaleString();
        }
      }
    }

    if (name.includes("percentile") || reg.test(Number(value))) {
      if (name.includes("salary")) {
        if (value.trim() === "" || values < 0) {
          values.salary[name] = "";
        } else {
          values.salary[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("percentile")) {
        if (value.trim() === "") {
          values.percentile[name] = "";
        } else if (value < 0) {
          values.percentile[name] = 0;
        } else if (value > 100) {
          values.percentile[name] = 100;
        } else {
          values.percentile[name] = customParseFloat(value).toLocaleString();
        }
      } else if (name.includes("marketCap")) {
        if (value.trim() === "" || values < 0) {
          values.marketCap[name] = 0;
        } else {
          values.marketCap[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("employees")) {
        if (value.trim() === "" || values < 0) {
          values.employees[name] = "";
        } else {
          values.employees[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("companyRank")) {
        if (value.trim() === "" || values < 0) {
          values.companyRank[name] = "";
        } else if (
          parseInt(value) > Object.keys(companies).length ||
          (filter.indexSelected !== "All" &&
            parseInt(value) > filter.index.length)
        ) {
          //?.[filter.currentYear].Tickers.length)
          values.companyRank[name] =
            filter.indexSelected === "All"
              ? Object.keys(companies).length?.toLocaleString()
              : filter.index.length?.toLocaleString(); //?.[filter.currentYear].Tickers.length?.toLocaleString();
        } else {
          values.companyRank[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("executiveRank")) {
        if (value.trim() === "" || values < 0) {
          values.executiveRank[name] = "";
        } else if (
          parseInt(value) > (maxExecutiveRanks?.[filter.currentYear] || 8)
        ) {
          values.executiveRank[name] =
            maxExecutiveRanks?.[filter.currentYear] || 8;
        } else {
          values.executiveRank[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("payRatio")) {
        if (value.trim() === "" || values < 0) {
          values.payRatio[name] = "";
        } else if (parseInt(value) > 1e5) {
          values.payRatio[name] = (1e5).toLocaleString();
        } else {
          values.payRatio[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("revenue")) {
        if (value.trim() === "" || values < 0) {
          values.revenue[name] = "";
        } else {
          values.revenue[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("netIncome")) {
        if (value.trim() === "" || values < 0) {
          values.netIncome[name] = "";
        } else {
          values.netIncome[name] = parseInt(value).toLocaleString();
        }
      } else if (name.includes("assets")) {
        if (value.trim() === "" || values < 0) {
          values.assets[name] = "";
        } else {
          values.assets[name] = parseInt(value).toLocaleString();
        }
      }
    }

    setFilter({ ...values });
    setTriggerDelay(true);

    dispatch(setTableFilter({ ...values }));
    if (currentTable === "first-filter") {
      dispatch(setFirstFilter({ ...values }));
    } else if (currentTable === "second-filter") {
      dispatch(setSecondFilter({ ...values }));
    }
  };

  const validateRange = (type) => {
    const values = { ...filter };
    let { percentileMin, percentileMax } = values.percentile;
    if (percentileMax === "") {
      values.percentile.percentileMax = 100;
    }
    if (percentileMin === "") {
      values.percentile.percentileMin = 0;
    }
    if (parseFloat(percentileMin) > parseFloat(percentileMax)) {
      if (type === "min") {
        values.percentile.percentileMax = 100;
      } else {
        values.percentile.percentileMin = 0;
      }
    }

    let { executiveRankMin, executiveRankMax } = values.executiveRank;
    if (executiveRankMax === "") {
      values.executiveRank.executiveRankMax =
        maxExecutiveRanks?.[filter.currentYear] || 8;
    }
    if (executiveRankMin === "") {
      values.executiveRank.executiveRankMin = 0;
    }
    if (parseFloat(executiveRankMin) > parseFloat(executiveRankMax)) {
      if (type === "min") {
        values.executiveRank.executiveRankMax =
          maxExecutiveRanks?.[filter.currentYear] || 8;
      } else {
        values.executiveRank.executiveRankMin = 0;
      }
    }

    let { payRatioMin, payRatioMax } = values.payRatio;
    if (payRatioMax === "") {
      values.payRatio.payRatioMax = (1e5).toLocaleString();
    }
    if (payRatioMin === "") {
      values.payRatio.payRatioMin = 0;
    }
    if (parseFloat(payRatioMin) > parseFloat(payRatioMax)) {
      if (type === "min") {
        values.payRatio.payRatioMax = (1e5).toLocaleString();
      } else {
        values.payRatio.payRatioMin = 0;
      }
    }

    let { salaryMin, salaryMax } = values.salary;
    if (salaryMin > salaryMax) {
      salaryMin = salaryMin ^ salaryMax;
      salaryMax = salaryMin ^ salaryMax;
      salaryMin = salaryMin ^ salaryMax;
      values.salary = {
        salaryMin,
        salaryMax,
      };
    }

    setFilter({ ...values });
    dispatch(setTableFilter({ ...values }));
    setTriggerUpdate(true);
    if (currentTable === "first-filter") {
      dispatch(setFirstFilter({ ...values }));
    } else if (currentTable === "second-filter") {
      dispatch(setSecondFilter({ ...values }));
    }
  };

  // Check window sizes for range filter: //ONLY using stacked for now - small filters cutting off 1,000,000 employees and 99.99 percentile
  // const [showCompactRangeFilter, setShowCompactRangeFilter] = useState(false);
  // window.innerWidth > 1450 ||
  //   (window.innerWidth < 800 && window.innerWidth > 500))
  // const updateMedia = () => {
  //   if (window.innerWidth > 5000) {
  //     setShowCompactRangeFilter(true);
  //   } else if (window.innerWidth < 800 && window.innerWidth > 500) {
  //     setShowCompactRangeFilter(true);
  //   } else {
  //     setShowCompactRangeFilter(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", updateMedia);
  //   return () => window.removeEventListener("resize", updateMedia);
  // });
  // ---end of dynamic filter styles --- //

  const rangeFilter = (name, params = {}) => {
    const handleBlur = () => {
      if (name === "percentile") {
        validateRange("min");
      }
      if (name === "executiveRank") {
        validateRange("min");
      }
      if (name === "payRatio") {
        validateRange("min");
      }
      if (
        name === "companyRank" &&
        (filter.companyRank.companyRankMin === "" ||
          filter.companyRank.companyRankMax === "")
      ) {
        let values = {
          ...filter,
          companyRank: {
            companyRankMin: filter.companyRank.companyRankMin || null,
            companyRankMax: filter.companyRank.companyRankMax || null,
          },
        };
        dispatch(setTableFilter({ ...values }));
        setTriggerUpdate(true);
      }
      if (
        name === "executiveRank" &&
        (filter.executiveRank.executiveRankMin === "" ||
          filter.executiveRank.executiveRankMax === "")
      ) {
        let values = {
          ...filter,
          executiveRank: {
            executiveRankMin: 1,
            executiveRankMax: maxExecutiveRanks?.[filter.currentYear] || 8,
          },
        };
        dispatch(setTableFilter({ ...values }));
        setTriggerUpdate(true);
      }
    };

    let showCompactRangeFilter = false;
    if (name === "executiveRank") {
      showCompactRangeFilter = true;
    }

    const minInput = () => (
      <>
        <input
          min="0"
          max={name === "percentile" ? "100" : ""}
          name={`${name}Min`}
          value={
            filter?.[`${name}`]?.[`${name}Min`] == null
              ? params?.placeholderMin
              : filter?.[`${name}`]?.[`${name}Min`]
          }
          type={name === "percentile" ? "number" : "text"}
          placeholder={params?.placeholderMin}
          onChange={handleChangeRange}
          onBlur={handleBlur}
        />
        {name === "payRatio" && <span>: 1</span>}
      </>
    );
    const maxInput = () => (
      <>
        <input
          min="0"
          max={name === "percentile" ? "100" : ""}
          name={`${name}Max`}
          value={
            filter?.[`${name}`]?.[`${name}Max`] == null
              ? params?.placeholderMax
              : filter?.[`${name}`]?.[`${name}Max`]
          }
          type={name === "percentile" ? "number" : "text"}
          placeholder={params?.placeholderMax}
          onChange={handleChangeRange}
          onBlur={handleBlur}
        />
        {name === "payRatio" && (
          <span style={{ marginLeft: 0, paddingLeft: 0 }}>: 1</span>
        )}
      </>
    );
    if (showCompactRangeFilter) {
      return (
        //side-by-side:
        <div className="row">
          <div className="col-6 range">
            <label>From:&nbsp;&nbsp;</label>
            {minInput()}
          </div>
          <div className="col-6 range">
            <label>To:</label>
            {maxInput()}
          </div>
        </div>
      );
    } else {
      //stacked vertically:
      return (
        <div className="row">
          <div className="range">
            <label>From:</label>
            {minInput()}
          </div>
          <div className="range">
            <label>To:&nbsp;&nbsp;&nbsp;&nbsp;</label>
            {maxInput()}
          </div>
        </div>
      );
    }
  };

  const getCohortTickers = (company) => {
    if (filter.cohortFilter === "custom") {
      return user?.customPeerGroups?.[company] || [];
    }

    const tickers = company.split("[")[1].replace("]", "") || [];
    for (const ticker of tickers.split(",")) {
      const cohortTickers =
        filter.cohortFilter === "analyst"
          ? companies[ticker]?.AnalystCohort || []
          : companies[ticker]?.ProxyCohort ||
            companies[ticker]?.ProxyCohortCompensation ||
            [];
      return cohortTickers;
    }
  };
  const getCompanyTickers = (ticker) => {
    const companyTickers = companies?.[ticker]?.AlternateTickers?.length
      ? companies[ticker].AlternateTickers.join()
      : companies[ticker]?.Ticker || null;
    return companyTickers;
  };
  const containsCustomCohort = useCallback(
    (cohortNames) =>
      user?.customPeerGroups &&
      cohortNames &&
      Object.keys(user?.customPeerGroups).some((item) =>
        cohortNames.some((name) => name === item)
      ),
    [user?.customPeerGroups]
  );

  const containsNonCustomCohort = useCallback(
    (cohortNames) =>
      cohortNames &&
      !cohortNames.every((name) =>
        Object.keys(user?.customPeerGroups).some((item) => item === name)
      ),
    [user?.customPeerGroups]
  );

  function updateCohortCompanies(cohortCompanies) {
    const isCustomCohort =
      filter.cohortFilter === "custom" || containsCustomCohort(filter.cohort);
    const getRemovedCohorts = (oldCohort, newCohort) => {
      let cohortsRemoved = [];
      if (oldCohort.length > newCohort.length) {
        cohortsRemoved = oldCohort.filter(
          (company) => !newCohort.includes(company)
        );
      }
      return cohortsRemoved;
    };

    const newCompanies = filter.company;
    const oldCohort =
      // containsCustomCohort(filter.cohort) ||
      // (isCustomCohort && containsNonCustomCohort(filter.cohort))
      //   ? []
      //   :
      filter.cohort;
    const cohortsRemoved = getRemovedCohorts(oldCohort, cohortCompanies);

    if (cohortsRemoved.length) {
      for (const cohortRemoved of cohortsRemoved) {
        const cohortTickers =
          // containsCustomCohort(filter.cohort) ||
          // (isCustomCohort && containsNonCustomCohort(filter.cohort))
          //   ? []
          //   :
          getCohortTickers(cohortRemoved);
        newCompanies.splice(newCompanies.indexOf(cohortRemoved), 1);
        for (let cohortTicker of cohortTickers) {
          cohortTicker = cohortTicker.split(".")[0]; // gets base level ticker (e.g. ABC.B => ABC)
          const companyTickers = getCompanyTickers(cohortTicker);
          if (companyTickers) {
            const companyOption =
              companies[cohortTicker].Company + " [" + companyTickers + "]";
            newCompanies.splice(newCompanies.indexOf(companyOption), 1);
          }
        }
      }
    }

    for (const company of cohortCompanies) {
      // include the company themselves, e.g. HD's cohort includes HD
      if (!newCompanies.includes(company) && !isCustomCohort) {
        newCompanies.push(company);
      }
      const cohortTickers =
        containsCustomCohort(filter.cohort) ||
        (isCustomCohort && containsNonCustomCohort(filter.cohort))
          ? []
          : getCohortTickers(company);
      for (let cohortTicker of cohortTickers) {
        cohortTicker = cohortTicker.split(".")[0]; // gets base level ticker (e.g. ABC.B => ABC)
        const companyTickers = getCompanyTickers(cohortTicker);
        if (companyTickers) {
          const companyOption =
            companies[cohortTicker].Company + " [" + companyTickers + "]";
          if (!newCompanies.includes(companyOption))
            newCompanies.push(companyOption);
        }
      }
    }

    return newCompanies;
  }

  function updateCohorts(filterCompanies) {
    const cohortCompanies =
      filterCompanies.length > 0 &&
      !(
        containsCustomCohort(filter.cohort) ||
        (filter.cohortFilter === "custom" &&
          containsNonCustomCohort(filter.cohort))
      )
        ? filter.cohort
        : [];
    for (const company of cohortCompanies) {
      // if (!companies.includes(company)) newCompanies.push(company);
      const cohortTickers = getCohortTickers(company);
      let cohortIsValid = false; // valid means company list contains at least one company from the cohort
      for (let cohortTicker of cohortTickers) {
        cohortTicker = cohortTicker.split(".")[0]; // gets base level ticker (e.g. ABC.B => ABC)
        const companyTickers = getCompanyTickers(cohortTicker);
        if (companyTickers) {
          const companyOption =
            companies[cohortTicker].Company + " [" + companyTickers + "]";
          if (
            filterCompanies.includes(companyOption) ||
            filterCompanies.includes(company)
          ) {
            cohortIsValid = true;
          }
        }
      }
      if (!cohortIsValid) {
        cohortCompanies.splice(cohortCompanies.indexOf(company), 1);
      }
    }
    return cohortCompanies;
  }

  const yearsAvailable = useMemo(() => {
    const yearsAvailable = [];
    for (let yr = parseInt(MAX_YEAR); yr >= CORRECTED_MIN_YEAR; yr--) {
      yearsAvailable.push(yr);
    }
    return yearsAvailable;
  }, [MAX_YEAR, CORRECTED_MIN_YEAR]);

  useEffect(() => {
    if (
      (filter.cohortFilter !== "custom" &&
        containsCustomCohort(filter.cohort)) ||
      (filter.cohortFilter === "custom" &&
        containsNonCustomCohort(filter.cohort))
    ) {
      const values = { ...filter, cohort: [], companies: [] };
      setFilter({ ...values });
      dispatch(setTableFilter({ ...values }));
    }
  }, [containsCustomCohort, containsNonCustomCohort, filter, dispatch]);

  const [boardPayLoaded, setBoardPayLoaded] = useState(false);
  useMemo(() => {
    const defaultFilter = JSON.parse(
      JSON.stringify({
        currentYear: MAX_YEAR,
        salary: {
          salaryMin: "",
          salaryMax: "",
        },
        executiveRank: {
          executiveRankMin: 1,
          executiveRankMax: maxExecutiveRanks?.[MAX_YEAR] || 8,
        },
        percentile: {
          percentileMin: 0,
          percentileMax: 100,
        },
        payRatio: {
          payRatioMin: 0,
          payRatioMax: (1e5).toLocaleString(),
        },
        industry: [],
        sector: [],
        company: [],
        cohortFilter: "analyst",
        cohort: [],
        executives: [],
        companyRank: {
          companyRankMin: null,
          companyRankMax: null,
        },
        marketCap: {
          marketCapMin: 0,
          marketCapMax: "",
          minDigit: 1e3,
          maxDigit: 1e3,
        },
        netIncome: {
          netIncomeMin: "",
          netIncomeMax: "",
          minDigit: 1e3,
          maxDigit: 1e3,
        },
        revenue: {
          revenueMin: "",
          revenueMax: "",
          minDigit: 1e3,
          maxDigit: 1e3,
        },
        assets: {
          assetsMin: "",
          assetsMax: "",
          minDigit: 1e3,
          maxDigit: 1e3,
        },
        employees: {
          employeesMin: "",
          employeesMax: "",
        },
        locationFilter: "company",
        companyState: [],
        companyCity: [],
        executiveState: [],
        executiveCity: [],
        indexSelected: "All",
        index: [],
        founders: "all",
        diffPercentAllowed: 0,
      })
    );
    if (
      csuite.includes("Board Pay") &&
      filter.currentYear < MAX_YEAR &&
      !boardPayLoaded
    ) {
      setBoardPayLoaded(true);
      const values = { ...defaultFilter, currentYear: MAX_YEAR };
      setFilter({ ...values });
      dispatch(setTableFilter({ ...values }));
    }
    if (!csuite.includes("Board Pay") && boardPayLoaded) {
      const values = { ...defaultFilter, currentYear: MAX_YEAR };
      setFilter({ ...values });
      dispatch(setTableFilter({ ...values }));
      setBoardPayLoaded(false);
    }
  }, [csuite, MAX_YEAR, filter, boardPayLoaded, dispatch]);

  return (
    <div className="table-filter">
      <div className="table-filter-content mt-3">
        <div className="result">
          Matching{" "}
          {csuite.includes("allExecutives")
            ? "Executives:"
            : csuite.join(", ") + "s:"}{" "}
          <div>
            {progressPercent <= 100
              ? "Loading..."
              : delay || update
              ? "Updating..."
              : result?.toLocaleString()}
          </div>
        </div>
        <div className="year filter geography">
          <div className="title">
            <h5>Year:</h5>
          </div>
          <select
            name="year"
            onChange={handleYearChange}
            value={filter?.currentYear || MAX_YEAR}
          >
            {yearsAvailable.map((yr, index) => (
              <option value={parseInt(yr)} key={index}>
                {yr}
              </option>
            ))}
          </select>
        </div>
        <div className="industry (sector)">
          <div className="title">
            <h5>Sector:</h5>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            // dropdownStyle={{ height: "500px" }}
            // listHeight="100%"
            loading={children?.length < 1}
            allowClear
            value={filter.sector}
            onChange={(value) => {
              const values = { ...filter, sector: value };
              setFilter({ ...values });
              dispatch(setTableFilter({ ...values }));
              setTriggerUpdate(true);
              setSearchVal("");
            }}
          >
            {children}
          </Select>
        </div>
        <div className="industry">
          <div className="title">
            <h5>Industry:</h5>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            // dropdownStyle={{ height: "500px" }}
            loading={children?.length < 1}
            allowClear
            value={filter.industry}
            onSearch={(val) => {
              setSearchVal(val);
            }}
            onBlur={() => {
              setSearchVal("");
            }}
            onChange={(value) => {
              const values = { ...filter, industry: value };
              setFilter({ ...values });
              dispatch(setTableFilter({ ...values }));
              setTriggerUpdate(true);
              setSearchVal("");
            }}
          >
            {renderIndustryOptions()}
          </Select>
        </div>
        <div className="industry (cohort_filter)">
          <div
            className="title _filter justify-content-start"
            style={{ fontWeight: "normal" }}
          >
            <select
              className="title"
              style={{
                width: "47.5%",
                fontSize: "0.85rem",
                padding: "2px 0px",
                marginRight: "5%",
                textAlign: "center",
                marginBottom: "5px",
                fontWeight: "normal",
              }}
              onChange={handleCohortChange}
              value={filter.cohortFilter}
            >
              <option value={"analyst"} key={1}>
                Analyst
              </option>
              <option value={"proxy"} key={2}>
                Proxy
              </option>
              {customCohortChildren.length && (
                <option value={"custom"} key={3}>
                  Custom
                </option>
              )}
            </select>
            {"    "}Cohort:
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            allowClear
            value={filter.cohort}
            onSearch={(val) => {
              setSearchVal(val);
            }}
            onBlur={() => {
              setSearchVal("");
            }}
            loading={Object.keys(companies)?.length < 8}
            onChange={(value) => {
              const companies = updateCohortCompanies(
                value,
                filter.cohortFilter === "custom"
              );
              const values = {
                ...filter,
                cohort: value,
                company: companies,
              };
              setFilter({ ...values });
              dispatch(setTableFilter({ ...values }));
              setTriggerUpdate(true);
              setSearchVal("");
            }}
          >
            {filter.cohortFilter === "custom"
              ? customCohortChildren
              : cohortChildren}
          </Select>
        </div>
        <div className="industry (company_filter)">
          <div className="title">
            <h5>Company:</h5>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            allowClear
            value={filter.company}
            onSearch={(val) => {
              setSearchVal(val);
            }}
            onBlur={() => {
              setSearchVal("");
            }}
            loading={Object.keys(companies)?.length < 8}
            onChange={(value) => {
              const cohorts = updateCohorts(value);
              const values = {
                ...filter,
                company: value,
                cohort: cohorts,
              };
              setFilter({ ...values });
              dispatch(setTableFilter({ ...values }));
              setTriggerUpdate(true);
              setSearchVal("");
            }}
          >
            {companyChildren}
          </Select>
        </div>
        <div className="industry (company_filter)">
          <div className="title">
            <h5>
              {csuite.includes("Board Pay") ? "Board Member" : "Executive"}{" "}
              Name:
            </h5>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            allowClear
            value={filter.executives}
            filterOption={false}
            onSearch={(val) => {
              setSearchVal(val);
            }}
            onBlur={() => {
              setSearchVal("");
            }}
            loading={executives?.length < 8}
            onChange={(value) => {
              const values = { ...filter, executives: value };
              setFilter({ ...values });
              dispatch(setTableFilter({ ...values }));
              setTriggerUpdate(true);
              setSearchVal("");
            }}
          >
            {executiveChildren}
          </Select>
        </div>
        {!csuite.includes("Board Pay") && (
          <div
            className="industry year geography"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="title" style={{ width: "100%" }}>
              <h5>Founders:</h5>
            </div>
            <select
              name="founder"
              onChange={handleFounderChange}
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "0.85rem",
              }}
              value={filter.founders}
            >
              <option value="all" key={1}>
                Founders and Non-founders
              </option>
              <option value="founders" key={0}>
                Founders Only
              </option>
              <option value="non-founders" key={2}>
                Non-founders Only
              </option>
            </select>
          </div>
        )}
        <div className="employee-count filter ">
          <div className="title">
            <h5>Employee Count:</h5>
          </div>
          {rangeFilter("employees")}
        </div>
        <div className="market-cap filter ">
          <div className="title">
            <h5>
              Current Market Cap <span>(USD)</span>:
            </h5>
          </div>
          <div className="range">
            <label>From:</label>

            <input
              min="0"
              name="marketCapMin"
              value={filter.marketCap.marketCapMin
                .toString()
                .replace(/^0+/, "")}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              min="0"
              name="marketCapMax"
              value={filter.marketCap.marketCapMax
                .toString()
                .replace(/^0+/, "")}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
        </div>
        <div className="assets filter">
          <div className="title">
            <h5>
              {filter.currentYear} Assets <span>(USD)</span>:
            </h5>
          </div>
          <div className="range">
            <label>From:</label>
            <input
              name="assetsMin"
              value={filter.assets.assetsMin}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              name="assetsMax"
              value={filter.assets.assetsMax}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
        </div>
        <div className="revenue filter">
          <div className="title">
            <h5>
              {filter.currentYear} Revenue <span>(USD)</span>:
            </h5>
          </div>
          <div className="range">
            <label>From:</label>
            <input
              name="revenueMin"
              value={filter.revenue.revenueMin}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              name="revenueMax"
              value={filter.revenue.revenueMax}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
        </div>
        <div className="netIncome filter ">
          <div className="title">
            <h5>
              {filter.currentYear} Net Income <span>(USD)</span>:
            </h5>
          </div>
          <div className="range">
            <label>From:</label>
            <input
              name="netIncomeMin"
              value={filter.netIncome.netIncomeMin}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              name="netIncomeMax"
              value={filter.netIncome.netIncomeMax}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
        </div>
        <div
          className="title _filter justify-content-start"
          style={{ fontWeight: "normal" }}
        >
          <select
            className="title"
            style={{
              width: "47.5%",
              fontSize: "0.85rem",
              padding: "2px 0px",
              marginRight: "5%",
              textAlign: "center",
              marginBottom: "5px",
              fontWeight: "normal",
            }}
            onChange={handleLocationChange}
            value={filter.locationFilter}
          >
            <option value={"company"} key={1}>
              Company
            </option>
            <option value={"executive"} key={2}>
              Executive
            </option>
          </select>
          {"    "}Location:
        </div>
        <div className="state _filter" style={{ marginTop: "-10px" }}>
          <div className="range">
            <label style={{ marginRight: "10px" }}>State:</label>
            {filter.locationFilter === "company" ? (
              <Select
                mode="multiple"
                style={{ width: "65%", margin: "10px 0px" }}
                // dropdownStyle={{ height: "500px" }}
                allowClear
                value={filter.companyState}
                onChange={(value) => {
                  const values = { ...filter, companyState: value };
                  setFilter({ ...values });
                  dispatch(setTableFilter({ ...values }));
                  setTriggerUpdate(true);
                }}
              >
                {stateChildren}
              </Select>
            ) : (
              <Select
                mode="multiple"
                style={{ width: "65%", margin: "10px 0px" }}
                allowClear
                value={filter.executiveState}
                onChange={(value) => {
                  const values = { ...filter, executiveState: value };
                  setFilter({ ...values });
                  dispatch(setTableFilter({ ...values }));
                  setTriggerUpdate(true);
                }}
              >
                {stateChildren}
              </Select>
            )}
          </div>
        </div>
        <div className="city _filter" style={{ marginTop: "-10px" }}>
          <div className="range">
            <label style={{ marginRight: "10px" }}>City:</label>
            {filter.locationFilter === "company" ? (
              <Select
                mode="multiple"
                style={{ width: "65%", margin: "10px 0px" }}
                // dropdownStyle={{ height: "500px" }}
                allowClear
                value={filter.companyCity}
                onChange={(value) => {
                  const values = { ...filter, companyCity: value };
                  setFilter({ ...values });
                  dispatch(setTableFilter({ ...values }));
                  setTriggerUpdate(true);
                }}
              >
                {validCitiesChildren}
              </Select>
            ) : (
              <Select
                mode="multiple"
                style={{ width: "65%", margin: "10px 0px" }}
                allowClear
                value={filter.executiveCity}
                onChange={(value) => {
                  const values = { ...filter, executiveCity: value };
                  setFilter({ ...values });
                  dispatch(setTableFilter({ ...values }));
                  setTriggerUpdate(true);
                }}
              >
                {validCitiesChildren}
              </Select>
            )}
          </div>
        </div>
        <div className="salary-range filter ">
          <div className="title">
            <h5>
              {filter.currentYear} Annual Compensation <span>(USD)</span>:
            </h5>
          </div>
          <div className="range">
            <label>From:</label>
            <input
              name="salaryMin"
              value={filter.salary.salaryMin}
              type="text"
              // onBlur={validateRange}
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              min="0"
              name="salaryMax"
              value={filter.salary.salaryMax}
              type="text"
              // onBlur={validateRange}
              onChange={handleChangeRange}
            />
          </div>
        </div>
        <div className="salary-range filter ">
          <div className="title">
            <h5>CEO Pay Ratio:</h5>
          </div>
          {rangeFilter("payRatio", {
            placeholderMin: 1,
            placeholderMax: (1e5).toLocaleString(),
          })}
        </div>
        {!csuite.includes("Board Pay") && (
          <div className="salary-range filter ">
            <div className="title">
              <h5>Executive Rank (Within Company):</h5>
            </div>
            {/* <div className="range">
            <label>From:</label>
            <input
              name="executiveRankMin"
              value={filter.executiveRank.executiveRankMin}
              type="text"
              onChange={handleChangeRange}
            />
          </div>
          <div className="range">
            <label>To:</label>
            <input
              min="0"
              name="executiveRankMax"
              value={filter.executiveRank.executiveRankMax}
              type="text"
              onChange={handleChangeRange}
            />
          </div> */}
            {rangeFilter("executiveRank", {
              placeholderMin: 1,
              placeholderMax: maxExecutiveRanks?.[filter.currentYear] || 8,
              // fallback set to 8 because that is the lowest of all the maxExecutiveRanks
            })}
          </div>
        )}

        <div className="percentile filter ">
          <div className="title">
            <h5>Compensation Percentile:</h5>
            <button
              onClick={() => {
                toggle(!show);
              }}
              style={!show ? { display: "none" } : {}}
            >
              <img
                src={require("../../assets/zoom_in.png").default}
                alt="search"
              />
            </button>
            <button
              onClick={() => {
                toggle(!show);
              }}
              style={show ? { display: "none" } : {}}
            >
              <img
                src={require("../../assets/zoom_out.png").default}
                alt="search"
              />
            </button>
          </div>
          <select
            style={!show ? { display: "none" } : {}}
            name="percent"
            onChange={(e) => {
              setFilter({
                ...filter,
                percentile: {
                  percentileMin: Number(e.target.value) || 0,
                  percentileMax: 100,
                },
              });
              dispatch(
                setTableFilter({
                  ...filter,
                  percentile: {
                    percentileMin: Number(e.target.value) || 0,
                    percentileMax: 100,
                  },
                })
              );
              setTriggerDelay(true);
            }}
          >
            <option value="0">0%</option>
            <option value="25">25%</option>
            <option value="50">50%</option>
            <option value="75">75%</option>
          </select>
          <div style={show ? { display: "none" } : {}}>
            {rangeFilter("percentile")}
            <div className="col-12">
              <Slider
                onChange={([min, max]) => {
                  setFilter({
                    ...filter,
                    percentile: {
                      percentileMin: min,
                      percentileMax: max,
                    },
                  });
                  dispatch(
                    setTableFilter({
                      ...filter,
                      percentile: {
                        percentileMin: min,
                        percentileMax: max,
                      },
                    })
                  );
                  if (currentTable === "first-filter") {
                    dispatch(
                      setFirstFilter({
                        ...filter,
                        percentile: {
                          percentileMin: min,
                          percentileMax: max,
                        },
                      })
                    );
                  } else if (currentTable === "second-filter") {
                    dispatch(
                      setSecondFilter({
                        ...filter,
                        percentile: {
                          percentileMin: min,
                          percentileMax: max,
                        },
                      })
                    );
                  }
                  setTriggerDelay(true);
                }}
                value={Object.values(filter.percentile)}
                range
              />
            </div>
          </div>
        </div>
        <div className="borderBox">
          <div className="year filter geography">
            <div className="title">
              <h5>Index:</h5>
            </div>
            <select
              name="index"
              onChange={handleIndexChange}
              value={filter.indexSelected}
              style={{ width: "65%", textAlign: "center" }}
            >
              <option value={"All"} key={0}>
                All
              </option>
              <option value={"Dow Jones"} key={1}>
                Dow Jones
              </option>
              <option value={"S&P 500"} key={2}>
                S&amp;P 500
              </option>
              <option value={"Russell 3000"} key={3}>
                Russell 3000
              </option>
            </select>
          </div>

          <div className="company-rank filter ">
            <div className="title">
              <h5>
                Current Company Rank ({filter.indexSelected}):
                {/* <span>
                (
                {filter.indexSelected === "All"
                  ? companies.length?.toLocaleString()
                  : filter.index?.[
                      filter.currentYear
                    ].Tickers.length?.toLocaleString()}{" "}
                companies total)
              </span> 
              : */}
              </h5>
            </div>
            {rangeFilter("companyRank", {
              placeholderMin: 1,
              placeholderMax:
                filter.indexSelected === "All"
                  ? Object.values(companies).length?.toLocaleString()
                  : filter.index.length?.toLocaleString(), //?.[filter.currentYear].Tickers.length?.toLocaleString(),
            })}
          </div>
        </div>
        <div
          className="borderBox"
          style={{
            marginTop: 5,
            border: !allowForDiffPercent ? "0px solid white" : "",
            borderRadius: !allowForDiffPercent ? "0px" : "",
            padding: !allowForDiffPercent ? "0px" : "",
          }}
        >
          <div className="percentile filter " style={{ paddingBottom: 5 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                flexWrap: "wrap",
              }}
            >
              <div className="title">
                <h5>Allow&nbsp;for&nbsp;Discrepancies:</h5>
              </div>
              <Switch
                checked={allowForDiffPercent}
                style={{ width: 5 }}
                onChange={() => {
                  setAllowForDiffPercent(!allowForDiffPercent);
                  const values = {
                    ...filter,
                    diffPercentAllowed: allowForDiffPercent ? 0 : 100,
                  };
                  setUseCalculatedTotals(false);
                  dispatch(setTableFilter({ ...values }));
                  setTriggerDelay(true);
                }}
              />
            </div>

            {allowForDiffPercent && (
              <>
                <div className="percentile filter ">
                  {!useCalculatedTotals && (
                    <>
                      <div>
                        Discrepancy&nbsp;Percentage Allowed:&nbsp;
                        <b>{filter.diffPercentAllowed}%</b>
                      </div>
                      <Slider
                        value={filter.diffPercentAllowed}
                        onChange={(val) => {
                          const values = {
                            ...filter,
                            diffPercentAllowed: val,
                          };
                          dispatch(setTableFilter({ ...values }));
                          setTriggerDelay(true);
                        }}
                        tooltipVisible={false}
                      />
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      flexWrap: "wrap",
                      marginTop: -5,
                    }}
                  >
                    <div className="title">
                      <h5>Use&nbsp;Calculated&nbsp;Totals:</h5>
                    </div>
                    <Switch
                      checked={useCalculatedTotals}
                      style={{ width: 5, marginBottom: 2 }}
                      onChange={() => {
                        setUseCalculatedTotals(!useCalculatedTotals);
                        setTriggerUpdate(true);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="reset text-center my-3">
          <ExportHomepagePDFButton
            setPDFprocessing={setPDFprocessing}
            setPDFprocessingForAllUsers={setPDFprocessingForAllUsers}
            allowExecutiveTableExport={allowExecutiveTableExport}
          />
        </div>
        <div className="reset text-center my-3">
          <button
            ref={buttonRef}
            onClick={() => {
              dispatch(resetTableFilter());
              setTriggerUpdate(true);
              handleResetPagination();
              setFilter({
                currentYear: MAX_YEAR,
                salary: {
                  salaryMin: "",
                  salaryMax: "",
                },
                executiveRank: {
                  executiveRankMin: 1,
                  executiveRankMax: maxExecutiveRanks?.[MAX_YEAR] || 8,
                },
                percentile: {
                  percentileMin: 0,
                  percentileMax: 100,
                },
                payRatio: {
                  payRatioMin: 0,
                  payRatioMax: (1e5).toLocaleString(),
                },
                industry: [],
                sector: [],
                company: [],
                cohortFilter: "analyst",
                cohort: [],
                executives: [],
                companyRank: {
                  companyRankMin: null,
                  companyRankMax: null,
                },
                marketCap: {
                  marketCapMin: 0,
                  marketCapMax: "",
                  minDigit: 1e3,
                  maxDigit: 1e3,
                },
                netIncome: {
                  netIncomeMin: "",
                  netIncomeMax: "",
                  minDigit: 1e3,
                  maxDigit: 1e3,
                },
                revenue: {
                  revenueMin: "",
                  revenueMax: "",
                  minDigit: 1e3,
                  maxDigit: 1e3,
                },
                assets: {
                  assetsMin: "",
                  assetsMax: "",
                  minDigit: 1e3,
                  maxDigit: 1e3,
                },
                employees: {
                  employeesMin: "",
                  employeesMax: "",
                },
                locationFilter: "company",
                companyState: [],
                companyCity: [],
                executiveState: [],
                executiveCity: [],
                indexSelected: "All",
                index: [],
                founders: "all",
                diffPercentAllowed: 0,
              });
            }}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="table-filter-overflow"></div>
    </div>
  );
}
