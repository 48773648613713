import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Radio, Switch, Tooltip } from "antd";
import FinalValue from "../utils/FinalValue";

export default function AwardsResults({
  allAwardsValues,
  totalAwardValue,
  negativePayoutStructure,
  numberOfTrialsCompleted,
}) {
  const awardBreakdownColumns = useMemo(() => {
    const defaultAwardBreakdownColumns = [
      { title: "Rank", dataIndex: "rank", key: "rank" },
      { title: "Percentile", dataIndex: "percentile", key: "percentile" },
      {
        title: "Award Multiplier",
        dataIndex: "multiplier",
        key: "multiplier",
        render: (cellData) => cellData?.toFixed(2),
      },
      {
        title: "Number of Awards",
        dataIndex: "numberOfAwards",
        key: "numberOfAwards",
        render: (cellData) =>
          cellData?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
      },
      {
        title: "Value of Awards",
        dataIndex: "valueOfAwards",
        key: "valueOfAwards",
        render: (cellData) =>
          "$" +
          cellData?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
      {
        title: "Chance of Percentile",
        dataIndex: "chanceOfPercentile",
        key: "chanceOfPercentile",
        render: (cellData) => cellData?.toFixed(2) + "%",
      },
      {
        title: "Value as Part of Total",
        dataIndex: "valueAsPartOfTotal",
        key: "valueAsPartOfTotal",
        render: (cellData) =>
          "$" +
          cellData?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
    ];
    if (negativePayoutStructure) {
      defaultAwardBreakdownColumns.splice(2, 0, {
        title: "Chance of Negative Return",
        dataIndex: "positiveReturn",
        key: "positiveReturn",
        render: (cellData, rowData) => {
          return (
            <Tooltip
              overlayInnerStyle={{ textAlign: "center" }}
              title={`${Math.round(
                cellData *
                  (rowData.chanceOfPercentile / 100) *
                  numberOfTrialsCompleted
              )} / ${Math.round(
                (rowData.chanceOfPercentile / 100) * numberOfTrialsCompleted
              )}
              trials resulted in a negative return at this rank.`}
            >
              <span>
                {(100 * cellData)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + "%"}
              </span>
            </Tooltip>
          );
        },
      });
    }
    return defaultAwardBreakdownColumns;
  }, [negativePayoutStructure, numberOfTrialsCompleted]);

  return (
    <div>
      {totalAwardValue > 0 && (
        <div>
          <div style={{ marginTop: 35 }}>
            <h3>Award Breakdown</h3>
            <Table
              dataSource={allAwardsValues}
              columns={awardBreakdownColumns}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
          <div style={{ marginTop: 35 }}>
            <FinalValue
              heading="Total Award Value"
              value={totalAwardValue}
              headingWidth={250}
              decimalPlaces={2}
              isDollar={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
